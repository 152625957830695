import "./App.css";
import React from "react";
import { AiFillLinkedin, AiOutlineHtml5 } from "react-icons/ai";
import { TbSql, TbBrandCss3, TbBrandReact } from "react-icons/tb";
import { SiAdobeindesign, SiAdobephotoshop, SiTailwindcss, SiTableau, SiMicrosoftoffice } from "react-icons/si";
import { MdWork, MdSchool } from "react-icons/md";
import logo from "./assets/logo_white_trans.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function App() {
  return (
    <div class="scroll-smooth bg-navy">
      <header>
        <title>Varun Anand Porfolio</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </header>
      <nav
        className="z-50 px-5 py-5 flex justify-between sticky top-0 backdrop-filter backdrop-blur-lg bg-opacity-90 shadow-lg shadow-gray-700
                      sm:px-10 "
      >
        <a href="#Home"> 
        <img
          src={logo}
          alt="logo"
          className="w-14 h-auto hover:bg-cyan-800 border border-teal-400  rounded-full
                    lg:w-20">

        </img>
        </a>
        {/* Example comment - <h1 className="font-wardrum_outline font-bold text-4xl ">VARUN ANAND</h1>*/}
        <ul className="flex items-center font-bold text-white">
          {/* 
              <li>
                <a className="px-4 py-2 hover:text-teal-400"
                href="#About">About</a>
              </li>
              */}

          <li>
            <a className="px-4 py-2 hover:text-teal-400" href="#Experience">
              Experience
            </a>
          </li>
          <li>
            <a className="px-4 py-2 hover:text-teal-400" href="#Contact">
              Contact
            </a>
          </li>
          <li>
            <a
              className="font-roboto_slab px-4 py-2 text-teal-300 border border-teal-400  rounded-md text-centre hover:bg-cyan-800"
              href="https://varunanand.co.uk/assets/Varun Anand's CV.pdf"
              download={"Varun Anand CV"}
            >
              CV
            </a>
          </li>
        </ul>
      </nav>
      <main className="bg-navy">
        <section id="Home" className="lg:min-h-screen 2xl:mt-48">
          <div
            className="text-white pt-14 px-12
                          lg:pt-30 lg:px-40
                          2xl:text-center"
          >
            <p className="font-roboto_slab text-teal-400">Hi, my name is</p>
            <h2
              className="text-4xl py-3
                        lg:text-5xl lg:py-3"
            >
              Varun Anand
            </h2>
            {/*
            <h3
              className="text-2xl 
                            lg:pt-10 pb-5 text-neutral-300"
            >
              Business Analyst
            </h3>
            */}
            <div>
              <p className="leading-7 text-neutral-400
                            2xl:mx-96">
                I'm a Business Analyst working closely with internal and external stakeholders
                to deliver solutions and implement improvements across a range
                of areas whilst employing a pragmatic approach. Currently growing the product portfolio with a global e-commerce company.
              </p>
              <div
                className="flex justify-center pt-10 px-12
                          lg:pt-30"
              >
                <a
                  className="hidden sm:inline-block text-7xl text-white hover:text-teal-400 max-w-none"
                  href="https://www.linkedin.com/in/varun-anand-755b14195/"
                >
                  <AiFillLinkedin />
                </a>
                <a
                  className="font-roboto_slab py-3.5 px-2 text-xl border border-teal-400 text-teal-300 rounded-md  text-centre hover:bg-cyan-800
                  sm:text-3xl"
                  href="#Contact"
                >
                  Lets Connect
                </a>
              </div>
            </div>

            <div>
            <h3
              className="mt-10 text-center text-white text-3xl pb-10 
              md:after:content-[''] md:after:w-80 md:after:h-0.5 md:after:-top-1 md:after:bg-gray-700 md:after:inline-block md:after:relative md:after:ml-5">
              Skills
            </h3>
            <div class="text-white grid gap-6 grid-cols-3 text-center text-xl" >
              <div>
                <SiMicrosoftoffice className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>
                Office Suite
              </div>
              <div>
                <TbSql className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>
                SQL (MSSQL)
              </div>
              <div>
                <SiAdobeindesign className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>
                Adobe InDesign
              </div>
              <div>
                <SiAdobephotoshop className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>
                Adobe Photoshop
              </div>
              <div>
                <AiOutlineHtml5 className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>
                HTML
              </div>
              <div>
                <TbBrandCss3 className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>
                CSS
              </div>
              <div>
                <SiTailwindcss className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>
                Tailwind CSS
              </div>
              <div>
                <TbBrandReact className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>
                React JS
              </div>
              <div>
                <SiTableau className="text-4xl hover:text-teal-400 inline-block"/>
                <br/>Tableau
              </div>
            </div>
          </div>

         </div>
          
        </section>
        {/* 
        <section>
          <h3 id="About" className="text-center text-white text-3xl pb-10 after:content-[''] after:w-24 after:h-0.5 after:-top-1 after:bg-gray-700 after:inline-block after:relative after:ml-5"> About</h3>
          </section>
        */}
        <section>
          <div>
            <h3
              id="Experience"
              className="mt-10 text-center text-white text-3xl pb-10 
              md:after:content-[''] md:after:w-80 md:after:h-0.5 md:after:-top-1 md:after:bg-gray-700 md:after:inline-block md:after:relative md:after:ml-5"
            >
              Experience & Education
            </h3>

            <VerticalTimeline>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(33, 150, 243)",
                }}
                date="June 2020 - Present"
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                icon={<MdWork />}
              >
                <h3 className="vertical-timeline-element-title">
                  Inspired Lighting, Manchester
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Business Analyst
                </h4>

                <li className="mt-4 leading-8 ml-2">Product potfolio management</li>
                <li className="leading-8 ml-2">Provide technical support</li>
                <li className="leading-8 ml-2">Business Analysis</li>
                <li className="leading-8 ml-2">SQL Data Manipulation</li>
                <li className="leading-8 ml-2">UX/UI Testing</li>
                <li className="leading-8 ml-2">Agile Framework</li>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "rgb(33, 150, 243)",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(33, 150, 243)",
                }}
                date="Summers 2018, 2019, June 2020 - Present"
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                icon={<MdWork />}
              >
                <h3 className="vertical-timeline-element-title">
                  Inspired Lighting, Manchester
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Marketing Assistant
                </h4>

                <li className="mt-4 leading-8 ml-2">Catalogue Design</li>
                <li className="leading-8 ml-2">Adobe InDesign</li>
                <li className="leading-8 ml-2">Adobe Photoshop</li>
                <li className="leading-8 ml-2">CorelDraw</li>
                <li className="leading-8 ml-2">Easycatalog</li>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                contentStyle={{
                  background: "rgb(25, 153, 147)",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(25, 153, 147)",
                }}
                date="September 2018 - July 2021"
                iconStyle={{ background: "rgb(25, 153, 147)", color: "#fff" }}
                icon={<MdSchool />}
              >
                <h3 className="vertical-timeline-element-title">
                  Sheffield Hallam University
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Computing BSc (2:1)
                </h4>

                <li className="mt-4 leading-8 ml-2">E-commerce</li>
                <li className="leading-8 ml-2">Data Management</li>
                <li className="leading-8 ml-2">
                  Web Application Design & Implementation
                </li>
                <li className="leading-8 ml-2">Project Planning</li>
                <li className="leading-8 ml-2">Service Support</li>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                contentStyle={{
                  background: "rgb(25, 153, 147)",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(25, 153, 147)",
                }}
                date="September 2016 - September 2018"
                iconStyle={{ background: "rgb(25, 153, 147)", color: "#fff" }}
                icon={<MdSchool />}
              >
                <h3 className="vertical-timeline-element-title">
                  Cheadle & Marple Sixth Form
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  A-levels: ICT (C), Business Studies (C), Sport (Distinction)
                </h4>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                contentStyle={{
                  background: "rgb(25, 153, 147)",
                  color: "#fff",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(25, 153, 147)",
                }}
                date="September 2011 - September 2016"
                iconStyle={{ background: "rgb(25, 153, 147)", color: "#fff" }}
                icon={<MdSchool />}
              >
                <h3 className="vertical-timeline-element-title">
                  Hazel Grove High School
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  GCSEs: 6 B’s (including Maths), 1 C (English Language)
                </h4>
              </VerticalTimelineElement>

              {/* 
              <VerticalTimelineElement
                iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
                icon={<AiFillStar />}
              />*/}
            </VerticalTimeline>
          </div>
        </section>

        <section className="pt-10 pb-20 text-center text-white">
          <div>
            
            <h3
              id="Contact"
              className=" text-3xl mb-10  
                        md:after:content-[''] md:after:w-80 after:h-0.5 md:after:-top-1 md:after:bg-gray-700 md:after:inline-block md:after:relative md:after:ml-5">
              Contact Me
             
            </h3>
            

            <a
              className="font-roboto_slab px-8 py-3.5 text-2xl border border-teal-400 text-teal-300 rounded-md mx-10 text-centre hover:bg-cyan-800"
              href="mailto:varun@varunanand.co.uk"
            >
              Say Hi!
            </a>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
